/* eslint-disable */
import React from "react";
import GlobalState from "src/components/Accessories/GlobalState";

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
    console.log("IntersectionObserver polyfilled)");
  }
};

export const wrapPageElement = ({ element, props }) => (
  <GlobalState initLang={props.pageContext.lang}>{element}</GlobalState>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }
}
